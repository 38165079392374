import { environment } from '#environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEWO } from 'app/helpers/interfaces/ewo.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EWOService {

  constructor(private http: HttpClient) {}

  getList({ cache = true } = {}): Observable<IEWO[]> {
    let url = `${environment.apiEndpoint}/sites`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }

    return this.http.get<IEWO[]>(url, options);
  }

  getById(equipmentClassId: number): Observable<IEWO> {
    return this.http.get<IEWO>(
      `${environment.apiEndpoint}/ewo/${equipmentClassId}`
    );
  }

  post(payload: IEWO): Observable<IEWO> {
    return this.http.post<IEWO>(
      `${environment.apiEndpoint}/ewo`,
      payload
    );
  }

  put(payload: IEWO): Observable<IEWO> {
    return this.http.put<IEWO>(
      `${environment.apiEndpoint}/ewo/`,
      payload
    );
  }

  getEWOs(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiEndpoint}/ewo`);
  }

  
}
