import { NgModule } from "@angular/core";

import { NotificationComponent } from "./notification/notification.component";
import { NotificationService } from "../services/shared";
import { CommonModule } from "@angular/common";
import { RatingModule } from "primeng/rating";
import { DialogModule } from "primeng/dialog";
import { ButtonModule } from "primeng/button";
import { InputTextareaModule } from "primeng/inputtextarea";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BigTitleComponent } from "./big-title/big-title.component";
import { ApiModule } from "../services/api/api.module";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { RouterModule } from "@angular/router";
import { InputTextModule } from "primeng/inputtext";
import { MultiSelectModule } from "primeng/multiselect";
import { TableModule } from "primeng/table";
import { TranslateModule } from "@ngx-translate/core";
import { SidebarModule } from "primeng/sidebar";
import { MenubarModule } from "primeng/menubar";
import { BreadcrumbModule } from "primeng/breadcrumb";

const components = [
  NotificationComponent,
  BigTitleComponent,
  BreadcrumbComponent,
];
@NgModule({
    declarations: components,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RatingModule,
        InputTextareaModule,
        ApiModule,
        RouterModule,
        // primeng
        BreadcrumbModule,
        InputTextModule,
        ButtonModule,
        MultiSelectModule,
        TableModule,
        DialogModule,
        SidebarModule,
        MenubarModule,
        //translate
        TranslateModule,
    ],
    providers: [NotificationService],
    exports: components
})
export class ComponentsModule {}
