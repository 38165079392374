import { environment } from '#environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISap } from 'app/pages/outage/outage.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SapService {
  constructor(private http: HttpClient) {}

  getList(cache = true): Observable<ISap[]> {
    let url = `${environment.apiEndpoint}/sap`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }

    return this.http.get<ISap[]>(url, options);
  }

  post(payload: ISap): Observable<ISap> {
    return this.http.post<ISap>(`${environment.apiEndpoint}/sap`, payload);
  }

  put(payload: ISap): Observable<ISap> {
    return this.http.put<ISap>(`${environment.apiEndpoint}/sap/${payload}`, payload);
  }

  getById(outageDetailId: number, cache = true): Observable<ISap> {
    let url = `${environment.apiEndpoint}/sap/${outageDetailId}`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }
    return this.http.get<ISap>(url, options);
  }

  getByOutage(outageID: number, cache = true): Observable<ISap[]> {
    let url = `${environment.apiEndpoint}/sap/get-by-outage/${outageID}`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }
    return this.http.get<ISap[]>(url, options);
  }
}
