import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, map, take } from "rxjs";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { IProjectCodeCostBySummary } from 'app/pages/outage/outage-summary/outage-tecode/outage-tecode.interface';

@Injectable({
  providedIn: "root",
})
export class ProjectCodeCostBySummaryService {
  constructor(private http: HttpClient) {}

  getList({ cache = true } = {}): Observable<IProjectCodeCostBySummary[]> {
    let url = `${environment.apiEndpoint}/cost-summary`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }

    return this.http.get<IProjectCodeCostBySummary[]>(url, options);
  }

  getById(projectCodeId: number): Observable<IProjectCodeCostBySummary> {
    return this.http.get<IProjectCodeCostBySummary>(
      `${environment.apiEndpoint}/cost-summary/${projectCodeId}`
    );
  }

  post(payload: IProjectCodeCostBySummary): Observable<IProjectCodeCostBySummary> {
    return this.http.post<IProjectCodeCostBySummary>(
      `${environment.apiEndpoint}/cost-summary`,
      payload
    );
  }

  put(payload: IProjectCodeCostBySummary): Observable<IProjectCodeCostBySummary> {
    return this.http.put<IProjectCodeCostBySummary>(
      `${environment.apiEndpoint}/cost-summary/`,
      payload
    );
  }

  getProjectCodeCostSummary(): Observable<IProjectCodeCostBySummary[]> {
    return this.http.get<IProjectCodeCostBySummary[]>(`${environment.apiEndpoint}/cost-summary`);
  }

  getEnabledProjectCodeCostSummary(): Observable<IProjectCodeCostBySummary[]> {
    return this.http.get<IProjectCodeCostBySummary[]>(
      `${environment.apiEndpoint}/cost-summary/getEnabled`
    );
  }

  
  downloadJanesReportSpreadSheet(outageID): Observable<any> {
    return this.http
      .get(`${environment.apiEndpoint}/sofa-excel/exportJanesReport/${outageID}`, {
        responseType: "blob",
      })
      .pipe(
        take(1),
        map((exportOutageResponse) => {
          const blob = new Blob([exportOutageResponse], {
            type: "blob",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "sumSofa.xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      );
  }


  getByOutage(outageID: number, cache = true): Observable<IProjectCodeCostBySummary[]> {
    let url = `${environment.apiEndpoint}/cost-summary/get-by-outage/${outageID}`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }
    return this.http.get<IProjectCodeCostBySummary[]>(url, options);
  }

}

export interface IAttachment {
  CreatedDate: string;
  FileName: string;
  FilePath: string;
  FileType: string;
  Extension: string;
}
