import { Component } from "@angular/core";
declare let require: any;

@Component({
  selector: "app-version",
  templateUrl: "./version.component.html",
  styleUrls: ["./version.component.scss"],
})
export class VersionComponent {
  version: string = require("../../../../package.json").version;
}
