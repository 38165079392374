import { NgModule } from "@angular/core";
import { LoggerService, NotificationService } from "./";
import { BreadcrumbService } from "./breadcrumb.service";

@NgModule({
  declarations: [],
  imports: [],
  providers: [LoggerService, NotificationService, BreadcrumbService],
})
export class SharedModule {}
