export const environment = {
  production: true,
  adalConfig: {
    tenant: "chevron.onmicrosoft.com",
    clientId: "101c57e4-58bf-4bb7-971b-7e39f3923443",
    postLogoutRedirectUri: "http://localhost:44300/logout",
    endpoints: {
      "https://chevron.onmicrosoft.com/e915774d-b77c-4155-b0f3-dfd7a9413885":
        "https://chevron.onmicrosoft.com/e915774d-b77c-4155-b0f3-dfd7a9413885",
    },
  },
  msalConfig: {
    scopes: ["https://EPCC-test.azure.chevron.com/user_impersonation"],
    clientId: "d36fb0c5-ecef-4052-9278-5305fc94b414",
  },
  // app insights
  appInsights: {
    connectionString: 'InstrumentationKey=4ade55bb-6afe-4fb4-b050-1558228e06ba;IngestionEndpoint=https://southcentralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://southcentralus.livediagnostics.monitor.azure.com/;ApplicationId=bcb0f292-8de4-44c5-87b8-96aa2b0e993d'
  }, 
  appCode: "webApp",
  apiEndpoint: "https://epcc-api-test.azure.chevron.com/api",
  feedbackEndpoint: "https://feedback-api-dev.cvx.azurewebsites.net/api",
  rateItEndpoint: "https://digitalpm-api-dev-cvx.azurewebsites.net",
};
