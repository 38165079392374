import { environment } from '#environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICraftResource, ITrack, ITrackCraftResource } from 'app/pages/crafts/craft.interface';
import { Observable, map } from 'rxjs';

@Injectable()
export class CraftService {
  constructor(private http: HttpClient) {}

  getTrackList({ cache = true } = {}): Observable<ITrack[]> {
    let url = `${environment.apiEndpoint}/track/get-unassigned`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }

    return this.http.get<ITrack[]>(url, options);
  }

  getTrackCraftResourceList(): Observable<ITrackCraftResource[]>{
    return this.http.get<ITrackCraftResource[]>(`${environment.apiEndpoint}/track-craft-resource`);
  }

  post(payload: ITrackCraftResource[]): Observable<ITrackCraftResource[]> {
    return this.http.post<ITrackCraftResource[]>(`${environment.apiEndpoint}/track-craft-resource`, payload);
  }

  getCraftsByVendor(VendorID: number): Observable<ICraftResource[]>{
    return this.http.get<ICraftResource[]>(`${environment.apiEndpoint}/craft-resource/get-by-vendor/${VendorID}`,
    {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      },
    });
  }

  getCraftsByCurrentSofaVersion(VersionID: number): Observable<ICraftResource[]>{
    return this.http.get<ICraftResource[]>(`${environment.apiEndpoint}/craft-resource/get-by-sofa-version/${VersionID}`,
    {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      },
    });
  }

  unAssignVendorCraft(payload: ICraftResource[]): Observable<any> {
    return this.http.post<ICraftResource[]>(`${environment.apiEndpoint}/craft-resource/unassign-crafts`, payload);
  }

  postCraftByVendor(payload: ICraftResource[]): Observable<any> {
    return this.http.post<ICraftResource[]>(`${environment.apiEndpoint}/craft-resource`, payload);
  }

  postMultipleCraftsByVendor(payload: ICraftResource[]): Observable<any> {
    return this.http.post<ICraftResource[]>(`${environment.apiEndpoint}/craft-resource/assign-crafts`, payload);
  }

  AssignCrafts(payload: ITrackCraftResource[]): Observable<ITrackCraftResource[]>{
    return this.http.post<ITrackCraftResource[]>(`${environment.apiEndpoint}/track-craft-resource/assign-trackcraftresource`,payload);
  }

  getTypeByCraftID(CraftResourceID): Observable<ITrackCraftResource[]>{
    return this.http.get<ITrackCraftResource[]>(`${environment.apiEndpoint}/track-craft-resource/get-by-craft/${CraftResourceID}`);
  }

  UnassignCrafts(payload: ITrackCraftResource[]): Observable<ITrackCraftResource[]>{
    return this.http.post<ITrackCraftResource[]>(`${environment.apiEndpoint}/track-craft-resource/unassign-trackcraftresource`,payload);
  }

}
