import { Component, Input } from "@angular/core";
import { BreadcrumbService } from "app/services/shared/breadcrumb.service";
import { MenuItem } from "primeng/api";

@Component({
  selector: "app-big-title",
  templateUrl: "./big-title.component.html",
})
export class BigTitleComponent {
  @Input()
  title: string;

  @Input()
  showBreadcrumb = false;

  constructor(private readonly _breadcrumbService: BreadcrumbService) {}

  get items(): MenuItem[] {
    return this._breadcrumbService.getBreadcrumbItems();
  }
}
