import { NgModule } from "@angular/core";
import { SiteService } from "./site.service";
import { SharedModule } from "../shared/shared.module";
import { UserService } from "./user.service";
import { VendorService } from "./vendor.service";
import { SoFaService } from "./sofa.service";
import { OutageService } from "./outage.service";
import { SofaDetailService } from "./sofa-detail.service";
import { EWOService } from "./ewo.service";
import { OutageDetailService } from "./outage-detail.service";
import { CraftService } from "./craft.service";
import { SofaCraftService } from "./sofa-craft.service";
import { SapService } from "./sap.service";
import { ProjectCodeService } from "./projectcode.service";
import { ProjectCodeCostBySummaryService } from "./projectcode-costsummary.service";
import { BusinessUnitsService } from "./business-unit.service";
import { StaffingPlanService } from "./staffingplan.service";
import { TradeService } from "./trade.service";


@NgModule({
  declarations: [],
  imports: [SharedModule],
  providers: [SiteService, UserService,TradeService, SoFaService, VendorService, OutageService,OutageDetailService, SofaDetailService, EWOService, CraftService, SofaCraftService, SapService, BusinessUnitsService,ProjectCodeService,ProjectCodeCostBySummaryService, StaffingPlanService],
})
export class ApiModule {}
