import { Injectable } from "@angular/core";
import { MenuItem } from "primeng/api";

@Injectable({
  providedIn: "root",
})
export class BreadcrumbService {
  private breadcrumbItems: MenuItem[] = [];

  // Add a new breadcrumb item if it doesn't already exist
  addBreadcrumbItemIfNotExists(item: MenuItem): void {
    if (!this.isBreadcrumbItemExists(item)) {
      this.breadcrumbItems.push(item);
    }
  }

  // Get all breadcrumb items
  getBreadcrumbItems(): MenuItem[] {
    return this.breadcrumbItems;
  }

  // Clear breadcrumb items up to the specified route
  clearBreadcrumbItems(route?: string): void {
    if (route) {
      const routeIndex = this.breadcrumbItems.findIndex(
        (item) => item.routerLink === route
      );
      if (routeIndex >= 0) {
        this.breadcrumbItems.splice(routeIndex + 1);
      }
    } else {
      this.breadcrumbItems = [];
    }
  }

  // Clear breadcrumb items up to the specified route
  clear(label?: string): void {
    if (label) {
      const routeIndex = this.breadcrumbItems.findIndex(
        (item) => item.label === label
      );
      if (routeIndex >= 0) {
        this.breadcrumbItems.splice(routeIndex + 1);
      }
    } else {
      this.breadcrumbItems = [];
    }
  }
  // Check if a breadcrumb item exists in the list
  private isBreadcrumbItemExists(item: MenuItem): boolean {
    return this.breadcrumbItems.some(
      (existingItem) => existingItem.routerLink === item.routerLink
    );
  }
}
