import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, Subject, of, throwError } from "rxjs";
import { AuthService } from "./../../services/shared";
import { catchError, switchMap } from "rxjs/operators";
import { MsalService } from "@azure/msal-angular";
import { CacheLookupPolicy } from "@azure/msal-browser";
import { environment } from "#environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;

  private tokenRefreshedSource = new Subject();
  private tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(public authSvc: AuthService,
    private readonly msalSvc: MsalService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = this.addAuthHeader(request);
    return next.handle(request).pipe(
      catchError((error) => {
        if (error?.status === 401) {
          const account = this.msalSvc.instance.getActiveAccount();
          return this.msalSvc
            .acquireTokenSilent({
              scopes: environment.msalConfig.scopes,
              account: account,
              forceRefresh: false,
              cacheLookupPolicy: CacheLookupPolicy.Default,
            })
            .pipe(
              switchMap((tokenResponse) => {
                sessionStorage.setItem("Token", tokenResponse.accessToken);
                request = this.addAuthHeader(request);
                return next.handle(request);
              })
            );
        }
        if (error.name == "TimeoutError") {
          return next.handle(request);
        }
        return throwError(error);
      })
    );
  }

  private addAuthHeader(request: HttpRequest<any>) {
    const authHeader = this.authSvc.getAuthorizationHeader();
    if (authHeader) {
      let ifBlob = false;
      if (request.url.includes("/sofa/export")) {
        ifBlob = true;
      }

      const headerSettings: { [name: string]: string | string[] } = {};

      for (const key of request.headers.keys()) {
        headerSettings[key] = request.headers.getAll(key);
      }
      headerSettings["Authorization"] = authHeader;
      if (sessionStorage.getItem("bu") !== null) {
        headerSettings["BU"] = sessionStorage.getItem("bu");
      }
      const newHeader = new HttpHeaders(headerSettings);

      return request.clone({
        headers: newHeader,

        ...(ifBlob ? { responseType: "blob" } : {}),
      });
    }
    return request;
  }

  private refreshToken() {
    // todo: handle refresh token
    if (this.refreshTokenInProgress) {
      return new Observable((observer) => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      this.authSvc.login();
      this.refreshTokenInProgress = false;
      return of();
    }
  }
}
