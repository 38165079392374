import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ComponentsModule } from "app/components/components.module";
import { InputTextModule } from "primeng/inputtext";
import { ButtonModule } from "primeng/button";
import { TableModule } from "primeng/table";
import { TabMenuModule } from "primeng/tabmenu";
import { LoginComponent } from "./login/login.component";
import { MultiSelectModule } from "primeng/multiselect";
import { RouterModule } from "@angular/router";
import { DropdownModule } from "primeng/dropdown";
import { DialogModule } from "primeng/dialog";
import { InputTextareaModule } from "primeng/inputtextarea";
import { EditorModule } from "primeng/editor";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "../services/shared/shared.module";
import { ApiModule } from "../services/api/api.module";
import { LoadingIndicatorService } from "../services/shared";

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ComponentsModule,
    ReactiveFormsModule,
    RouterModule,

    // PrimeNG
    InputTextModule,
    ButtonModule,
    MultiSelectModule,
    DropdownModule,
    TableModule,
    ButtonModule,
    DialogModule,
    InputTextareaModule,
    EditorModule,
    TabMenuModule,
    // App
    RouterModule,
    ApiModule,

    //translate
    TranslateModule,
  ],
  providers: [LoadingIndicatorService],
  exports: [],
})
export class PagesModule {}
