import { ApplicationRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MsalService, MsalBroadcastService } from "@azure/msal-angular";
import {
  EventMessage,
  EventType,
  InteractionStatus,
} from "@azure/msal-browser";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { filter, finalize, takeUntil } from "rxjs/operators";
import { LoggedUser } from "app/models/logged-user";
import { IUserRole } from "app/helpers/interfaces/user/user-role.interface";
import { AuthService, LoadingIndicatorService } from "../../services/shared";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit, OnDestroy {
  loginDisplay = false;
  private readonly _destroying$ = new Subject<boolean>();

  constructor(
    private readonly _authService: AuthService,
    public translate: TranslateService,
    private _msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private readonly _appRef: ApplicationRef,
    private readonly _router: Router,
    private readonly _loadingIndicatorService: LoadingIndicatorService
  ) {
    translate.addLangs(["en", "ru", "kz"]);
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang("en");
  }

  showDisclaimer: boolean;
  currentUserId: number;

  ngOnInit(): void {
    this.setLoginDisplay();

    this._msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this._msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        if (this._authService.isAuthenticated()) {
          this._getUserData();
        }
      });

    if (this._authService.isAuthenticated()) {
      this._getUserData();
    } else {
      this._authService.login();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(true);
    this._destroying$.complete();
  }

  iAccept() {
    this._authService.acceptDisclaimer(this.currentUserId);
  }
  setLoginDisplay() {
    this.loginDisplay = this._msalService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this._msalService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this._msalService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this._msalService.instance.getAllAccounts();
      this._msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  private _getUserData(): void {
    this._loadingIndicatorService.show();
    this._authService
      .getUserData()
      .pipe(
        finalize(() => {
          this._appRef.tick();
          this._loadingIndicatorService.hide();
        })
      )
      .subscribe(() => {
        const currentUser: LoggedUser = this._authService.getCurrentUser();
        const roles: IUserRole[] = this._authService.getRoles();
        this.currentUserId = currentUser?.ID;
        if (
          !currentUser?.IsDisabled &&
          (roles?.length > 0 || currentUser?.IsSystemAdmin)
        ) {
          sessionStorage.setItem("user", this.currentUserId.toString());
          if (currentUser.DisclaimerAccepted === false) {
            this.showDisclaimer = true;
          } else {
            this._router.navigate(["/outage"]);
          }
        } else {
          this._router.navigate(["/error/not-authorized"]);
        }
      });
  }
}
