/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { ITrade } from "app/pages/crafts/craft.interface";

@Injectable()
export class TradeService {
  constructor(private http: HttpClient) {}

  getTrades(): Observable<ITrade[]> {
    return this.http.get<ITrade[]>(`${environment.apiEndpoint}/trade`);
  }
}
