import { ChangeDetectorRef, Component, DoCheck } from "@angular/core";
import { AuthService } from "../../services/shared";
import { LoggedUser } from "../../models/logged-user";
import { TranslateService } from "@ngx-translate/core";
import { MenuItem } from "primeng/api";

interface ILanguage {
  name: string;
  code: string;
}
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements DoCheck {
  languages: ILanguage[];

  selectedLang: ILanguage;

  currentUser: LoggedUser;
  userRoles: Array<number> = [];
  isSysAdmin: boolean;

  // menu: Array<MenuItem> = [
  //   {
  //     title: "components.header.events",
  //     url: "/events",
  //     allowAccess: "1,2,3,4",
  //   },
  //   {
  //     title: "components.header.administration",
  //     url: null,
  //     admin: true,
  //     items: [
  //       {
  //         title: "components.header.users",
  //         url: "/admin/users",
  //         admin: true,
  //       },
  //       {
  //         title: "components.header.sites",
  //         url: "/admin/sites",
  //         allowAccess: "1,2,3",
  //       },
  //     ],
  //   },
  // ];

  constructor(
    private translate: TranslateService,
    private readonly _authService: AuthService,
    private readonly _cdr: ChangeDetectorRef
  ) {
    this.languages = [
      {
        name: "https://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg",
        code: "en",
      },
    ];
    this.selectedLang = this.languages[0];
  }

  items: MenuItem[] = [
    {
      label: "outage",
      routerLink: ["/outage"],
    },
    {
      label: "vendors",
      routerLink: ["/vendors"],
    },
    {
      label: "administration",
      items: [
        {
          label: "business units",
          routerLink: ["/admin/business-units"],
        },
        {
          label: "sites",
          routerLink: ["/admin/sites"],
        },
        {
          label: "users",
          routerLink: ["/admin/users"],
        },
        {
          label: "equipment class",
          routerLink: ["/equipment-class"],
        },
        // {
        //   label: "project code",
        //   routerLink: ["/project-code"],
        // },
        {
          label: "crafts",
          routerLink: ["/crafts"],
        },
      ],
    },
  ];

  ngDoCheck(): void {
    if (
      this.currentUser === undefined ||
      this.currentUser === null ||
      (this.userRoles.length === 0 && this.currentUser.IsSystemAdmin === false)
    )
      this.getCurrentUser();
  }

  onChange(event) {
    this.translate.use(event.value["code"]);
  }

  getTranslations(key: string) {
    this.translate.get(key).subscribe((translatedString) => {
      if (translatedString === undefined || translatedString == "") {
        return ""; // if value is getting undefined then return default language string, en_merch is default english language file here
      } else {
        return translatedString;
      }
    });
  }

  getCurrentUser(): void {
    this.currentUser = this._authService.getCurrentUser();
    this.userRoles = this._authService.getRoles()?.map((r) => r.Role);
    this.isSysAdmin = this._authService.isSystemAdmin();
    this._cdr.detectChanges();
  }

  getFullName(): string {
    return this._authService.getFullName();
  }
}
