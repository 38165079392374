import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { IOutageDetail } from "app/pages/outage/outage-detail.interface";

@Injectable()
export class OutageDetailService {
  constructor(private http: HttpClient) {}

  getList(cache = true): Observable<IOutageDetail[]> {
    let url = `${environment.apiEndpoint}/outage-detail`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }

    return this.http.get<IOutageDetail[]>(url, options);
  }

  post(payload: IOutageDetail): Observable<IOutageDetail> {
    return this.http.post<IOutageDetail>(
      `${environment.apiEndpoint}/outage-detail`,
      payload
    );
  }
  put(outagePayload: IOutageDetail, cache = true): Observable<IOutageDetail> {
    let url = `${environment.apiEndpoint}/outage-detail/update-outage-detail/${outagePayload}`;
    let options = {};
    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }
    return this.http.put<IOutageDetail>(url, outagePayload, options);
  }

  getById(outageDetailId: number, cache = true): Observable<IOutageDetail> {
    let url = `${environment.apiEndpoint}/outage-detail/${outageDetailId}`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }
    return this.http.get<IOutageDetail>(url, options);
  }

  getByOutage(outageId: number, cache = true): Observable<IOutageDetail[]> {
    let url = `${environment.apiEndpoint}/outage-detail/get-by-outage/${outageId}`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "CacheProfileName": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }
    return this.http.get<IOutageDetail[]>(url);
  }

  getByVendor(vendorId: number, cache = true): Observable<IOutageDetail[]> {
    let url = `${environment.apiEndpoint}/outage-detail/get-by-vendor/${vendorId}`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }
    return this.http.get<IOutageDetail[]>(url, options);
  }

  removeOutage(outageId: number, cache = true): Observable<IOutageDetail[]> {
    let url = `${environment.apiEndpoint}/outage/remove/${outageId}`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }
    return this.http.put<IOutageDetail[]>(url, options);
  }
}