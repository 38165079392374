import { environment } from "#environments/environment";
import { formatDate } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ISoFa } from "app/pages/sofa/sofa.interface";
import { Observable, map, take } from "rxjs";



@Injectable()
export class StaffingPlanService {
    constructor(private http: HttpClient) {}

    exportTurnaroundHeadcountByVersionID(sofa: ISoFa) : Observable<any> {
      return this.http
        .get(`${environment.apiEndpoint}/staffing-plan/export-headcount/${sofa.ID}/version/${sofa.VersionId}`, {responseType: 'blob'})
        .pipe(
          take(1),
          map((exportResponse) => {
            const blob = new Blob([exportResponse], {
              type: "blob",
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `Turnaround_Headcount_SoFA (${sofa.Name})-${formatDate(new Date(), 'yyyyMMddHHmmss', 'en')}_${sofa.Version}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
        );
    }


    exportTurnaroundHeadcountBySoFaID(sofa: ISoFa) : Observable<any> {
        return this.http
          .get(`${environment.apiEndpoint}/staffing-plan/export-headcount/${sofa.ID}`, {responseType: 'blob'})
          .pipe(
            take(1),
            map((exportResponse) => {
              const blob = new Blob([exportResponse], {
                type: "blob",
              });
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = `Turnaround_Headcount_SoFA (${sofa.Name})-${formatDate(new Date(), 'yyyyMMddHHmmss', 'en')}.xlsx`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
          );
      }

      exportTurnaroundHeadcountByOutage(outageId: number) : Observable<any> {
        return this.http
          .get(`${environment.apiEndpoint}/staffing-plan/export-headcount-outage/${outageId}`, {responseType: 'blob'})
          .pipe(
            take(1),
            map((exportResponse) => {
              const blob = new Blob([exportResponse], {
                type: "blob",
              });
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = `Turnaround_Headcount_Outage-${formatDate(new Date(), 'yyyyMMddHHmmss', 'en')}.xlsx`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
          );
      }
}

 
