/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ISofaCraftResource } from "app/pages/sofa/sofa.interface";
import { environment } from "#environments/environment";
import { ICraft } from "app/pages/crafts/craft.interface";

@Injectable()
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class SofaCraftService {
  constructor(private http: HttpClient) {}

  getSoFaCraftsByVendor(): Observable<ISofaCraftResource[]> {
    return this.http.get<ISofaCraftResource[]>(
      `${environment.apiEndpoint}/sofa-craftresource`
    );
  }

  getSoFaCraftsByVendorSoFaID(
    soFaID: number
  ): Observable<ISofaCraftResource[]> {
    return this.http.get<ISofaCraftResource[]>(
      `${environment.apiEndpoint}/sofa-craftresource/sofaId/${soFaID}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  postSoFaCraftByVendor(
    payload: ISofaCraftResource
  ): Observable<ISofaCraftResource> {
    return this.http.post<ISofaCraftResource>(
      `${environment.apiEndpoint}/sofa-craftresource`,
      payload
    );
  }

  postMultipleSoFaCraftsByVendor(
    payload: ISofaCraftResource[] | ICraft[]
  ): Observable<ISofaCraftResource[]> {
    return this.http.post<ISofaCraftResource[]>(
      `${environment.apiEndpoint}/sofa-craftresource/assign-sofacrafts`,
      payload
    );
  }

  unAssignSoFaCraftsByVendor(
    payload: ISofaCraftResource[] | ICraft[]
  ): Observable<ISofaCraftResource[]> {
    return this.http.post<ISofaCraftResource[]>(
      `${environment.apiEndpoint}/sofa-craftresource/unassign-sofacrafts`,
      payload
    );
  }

  editSoFaCraftsByVendor(payload: ICraft[]): Observable<ICraft[]> {
    return this.http.put<ICraft[]>(
      `${environment.apiEndpoint}/sofa-craftresource/edit-sofacrafts`,
      payload
    );
  }
}
