import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { IOutage } from "app/pages/outage/outage.interface";

@Injectable()
export class OutageService {
  constructor(private http: HttpClient) {}

  getList( cache = true, getOnlyEnabled = false): Observable<IOutage[]> {
    let url = `${environment.apiEndpoint}/outage`;

    let params = new HttpParams();
    params = params.append('getOnlyEnabled', String(getOnlyEnabled));

    let options = {headers:{}, params: params};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
        params: params,
      };
    }

    return this.http.get<IOutage[]>(url, options);
  }

  getExpandedList( cache = false, getOnlyEnabled = false): Observable<IOutage[]> {
    let url = `${environment.apiEndpoint}/outage/expanded`;

    let params = new HttpParams();
    params = params.append('getOnlyEnabled', String(getOnlyEnabled));

     let options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
        params: params,
      };

    return this.http.get<IOutage[]>(url, options);
  }

  post(payload: IOutage): Observable<IOutage> {
    return this.http.post<IOutage>(`${environment.apiEndpoint}/outage`, payload);
  }

  put(payload: IOutage): Observable<IOutage> {
    return this.http.put<IOutage>(`${environment.apiEndpoint}/outage`, payload);
  }

  createWOTasks(outageId: number): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/outage/create-wotasks/${outageId}`, null);
  }
    
  getById(outageId: number, { cache = true } = {}): Observable<IOutage> {
    let url = `${environment.apiEndpoint}/outage/${outageId}`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }
    return this.http.get<IOutage>(url, options);
  }

  getByVendor(vendorId: number, { cache = true } = {}): Observable<IOutage[]> {
    let url = `${environment.apiEndpoint}/outage/get-by-vendor/${vendorId}`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }
    return this.http.get<IOutage[]>(url, options);
  }
  
  getImportWOandTasksFromAPIM(outageId: number, { cache = true } = {}): Observable<any> {
    let url = `${environment.apiEndpoint}/outage/import/${outageId}`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }
    return this.http.post<any>(url, options);
  }
}

