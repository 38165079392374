import { Component, Input } from "@angular/core";
import { Breadcrumb } from "app/models/utilities/breadcrumb.model";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent {
  @Input()
  routes: Array<Breadcrumb>;
}
