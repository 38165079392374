import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { IVendor } from "app/pages/vendors/vendor.interface";

@Injectable()
export class VendorService {
  constructor(private http: HttpClient) {}

  getList({ cache = true } = {}): Observable<IVendor[]> {
    let url = `${environment.apiEndpoint}/sites`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }

    return this.http.get<IVendor[]>(url, options);
  }

  getById(vendorId: number): Observable<IVendor> {
    return this.http.get<IVendor>(
      `${environment.apiEndpoint}/vendor/${vendorId}`
    );
  }

  post(payload: IVendor): Observable<IVendor> {
    return this.http.post<IVendor>(
      `${environment.apiEndpoint}/vendor`,
      payload
    );
  }

  put(payload: IVendor): Observable<IVendor> {
    return this.http.put<IVendor>(`${environment.apiEndpoint}/vendor`, payload);
  }

  getVendors(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiEndpoint}/vendor`);
  }

  getEnabledVendors(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiEndpoint}/vendor`);
  }

  getVendorsFromAPIM(): Observable<any[]> {
    return this.http.post<any[]>(
      `${environment.apiEndpoint}/vendor/ImportVendors`,
      null
    );
  }

  getByOutage(outageID: number, cache = true): Observable<IVendor[]> {
    let url = `${environment.apiEndpoint}/vendor/get-by-outage/${outageID}`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }
    return this.http.get<IVendor[]>(url, options);
  }
}
