import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  EventEmitter,
} from "@angular/core";
import { NotificationService } from "../../services/shared/notification.service";
import { NotificationConfig } from "app/models/notification-config";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent implements OnInit, OnDestroy {
  private subscription: EventEmitter<string>;

  public visible: boolean;

  @Input()
  id: string;

  @Input()
  message: string;

  @Input()
  type: string;

  @Input()
  autoClose: number;

  constructor(private notificationSvc: NotificationService) {}

  ngOnInit() {
    this.subscription = this.notificationSvc
      .getNotificationSubscription()
      .subscribe((config: NotificationConfig) =>
        this.eventReceived(config)
      ) as any;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public cssClass(): string {
    switch (this.type) {
      case "success":
        return "alert-success";

      case "info":
        return "alert-info";

      case "warning":
        return "alert-warning";

      case "error":
      case "danger":
        return "alert-danger";

      default:
        return "";
    }
  }

  public hide(): void {
    this.visible = false;
  }

  private eventReceived(config: NotificationConfig): void {
    if (config.id !== this.id) {
      return;
    }

    this.visible = true;

    this.message = config.message || "";
    this.type = config.type || "";
    this.autoClose = config.autoCloseTimeout || 0;

    if (this.autoClose > 0) {
      setTimeout(() => (this.visible = false), this.autoClose);
    }
  }
}
