import { HttpClient } from "@angular/common/http";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { BusinessUnit } from "app/models/business-unit";

import { Observable } from "rxjs";
import { BusinessUnitUser } from "app/models/business-unit-user";


@Injectable()
export class BusinessUnitsService {
  constructor(private http: HttpClient) {}

  getList(): Observable<BusinessUnit[]> {
    return this.http.get<BusinessUnit[]>(
      `${environment.apiEndpoint}/business-units`, 
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  public getBusinessUnitsAsAdmin(): Observable<BusinessUnit[]> {
    return this.http.get<BusinessUnit[]>(
      `${environment.apiEndpoint}/business-units/currentUserAdminBUs`
    );
  }

  public create(businessUnit: BusinessUnit): Observable<BusinessUnit> {
    return this.http.post<BusinessUnit>(
      `${environment.apiEndpoint}/business-units`,
      businessUnit
    );
  }

  public getUsers(businessUnitId: number): Observable<BusinessUnitUser[]> {
    return this.http.get<BusinessUnitUser[]>(
      `${environment.apiEndpoint}/business-unit/businessUnitUsers/${businessUnitId}`
    );
  }

  public addUser(buUser: BusinessUnitUser): Observable<BusinessUnitUser> {
    return this.http.post<BusinessUnitUser>(
      `${environment.apiEndpoint}/business-unit/addBusinessUnitUser`,
      buUser
    );
  }

  public removeUser(id: number): Observable<BusinessUnitUser> {
    return this.http.put<BusinessUnitUser>(
      `${environment.apiEndpoint}/business-unit/removeBusinessUnitUser/${id}`,
      null
    );
  }

  public disableBusinessUnit(businessUnitID: number): Observable<any> {
    return this.http.post<any>(
      `${environment.apiEndpoint}/business-units/${businessUnitID}/disable`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  public enableBusinessUnit(businessUnitID: number): Observable<any> {
    return this.http.post<any>(
      `${environment.apiEndpoint}/business-units/${businessUnitID}/enable`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }
}
