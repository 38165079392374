import { Component } from "@angular/core";
import { LoadingIndicatorService } from "../../services/shared/loading-indicator.service";

@Component({
  selector: "app-loading-indicator",
  templateUrl: "./loading-indicator.component.html",
  styleUrls: ["./loading-indicator.component.scss"],
})
export class LoadingIndicatorComponent {
  constructor(public loadingIndicatorSvc: LoadingIndicatorService) {}
}
